import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Page from 'components/page';
import { useViewModal } from './view-modal-hooks';
import ProductViewSummary from 'components/modals/review-edit-summary/components/product-view-summary';
import ViewSelector from 'components/modals/review-edit-summary/components/view-selector';
import { PrivateScreen } from 'routes/screen-names';
import SessionStorageManager from 'utils/session-storage-manager';

const ViewModal = () => {
  const {
    canvas,
    navigate,
    fetchCustomizeProductSummary,
    productCustomizer,
    changeCameraAngle,
    productSumaryData,
    viewOptions,
    customizerDetails,
  } = useViewModal();
  return (
    <Page
      fullPage
      backButtonText='Back'
      backButtonHref={SessionStorageManager?.getSessionStorage(
        'backToCustomizer'
      )}
    >
      <Box
        id='customizer_container'
        sx={{
          height: '520px',
          width: '100%',
        }}
      >
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} sm={6} sx={{ height: '100%', paddingTop: '22px' }}>
            <Box
              sx={{
                // width: 'fit-content',
                height: '100%',
              }}
            >
              <ProductViewSummary summaryData={productSumaryData} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <canvas
              ref={canvas}
              style={{ height: '84%', width: '100%', marginBottom: '24px' }}
            />
            <ViewSelector
              viewOptions={viewOptions}
              onSelect={(value) => {
                changeCameraAngle({
                  productCustomizer: productCustomizer,
                  cameraAngle: value?.value,
                });
                fetchCustomizeProductSummary({ cameraAngleId: value?.id });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default ViewModal;
