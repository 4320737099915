import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack, IconButton, Box } from '@mui/material';
import ArrowNextIcon from 'assets/icons/arrow-next-icon';
import ArrowPrevIcon from 'assets/icons/arrow-prev-icon';

const ViewSelector = ({ viewOptions, onSelect }) => {
  const [selectedView, setSelectedView] = useState();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '20px',
      }}
    >
      <IconButton
        sx={{
          width: '24px',
          height: '24px',
          border: '1px solid #D9D9D9',
          borderRadius: '50%',
          boxShadow: '0px 3px 6px #0000000F',
        }}
      >
        <ArrowPrevIcon width='6' height='12' />
      </IconButton>
      <Stack spacing={1} direction='row'>
        {viewOptions?.map((item) => (
          <Box
            sx={[
              {
                width: '46px',
                height: '46px',
                backgroundColor: '#FFFFFF',
                cursor: 'pointer',
              },
              selectedView == item?.value
                ? { border: '2px solid var(--primary-border-color)' }
                : { border: '0.7px solid var(--primary-border-color)' },
            ]}
            key={item.id}
            onClick={() => {
              setSelectedView(item?.value);
              onSelect(item);
            }}
          >
            {/* {item?.label} */}
            <img
              src={item?.imgSrc}
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Box>
        ))}
      </Stack>
      <IconButton
        sx={{
          width: '24px',
          height: '24px',
          border: '1px solid #D9D9D9',
          borderRadius: '50%',
          boxShadow: '0px 3px 6px #0000000F',
        }}
      >
        <ArrowNextIcon width='6' height='12' />
      </IconButton>
    </Box>
  );
};

ViewSelector.propTypes = {
  viewOptions: PropTypes.array,
  onSelect: PropTypes.func,
};
ViewSelector.defaultProps = {
  viewOptions: [],
  onSelect: () => {},
};
export default ViewSelector;
