import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from '../../api/index';
import { snackbarTypes } from 'constants';
import { showSnackbar } from 'components/snackbar/actions';

const namespace = 'pages/customizer/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_CUSTOMIZATION_ID = `${namespace}GET_CUSTOMIZATION_ID`;
export const CUSTOMIZER_DETAILS = `${namespace}CUSTOMIZER_DETAILS`;
export const SAVE_CUSTOMIZER_DETAILS = `${namespace}SAVE_CUSTOMIZER_DETAILS`;
export const GET_CUSTOMIZED_DRAFT_LIST = `${namespace}GET_CUSTOMIZED_DRAFT_LIST`;
export const GET_CUSTOMIZER_PRODUCT_SUMMARY = `${namespace}GET_CUSTOMIZER_PRODUCT_SUMMARY`;
export const GET_CUSTOMIZED_DRAFT_ORDER_DETAILS = `${namespace}GET_CUSTOMIZED_DRAFT_ORDER_DETAILS`;
export const UPDATE_CUSTOMIZED_PRODUCT_QUANTITY = `${namespace}UPDATE_CUSTOMIZED_PRODUCT_QUANTITY`;
export const TRANSFORM_DRAFT_TO_ORDER = `${namespace}TRANSFORM_DRAFT_TO_ORDER`;
export const ADD_UPDATE_CUSTOMIZATION_DISCOUNT = `${namespace}ADD_UPDATE_CUSTOMIZATION_DISCOUNT`;
export const UPDATE_CUSTOMIZED_PRODUCT_COMMENT = `${namespace}UPDATE_CUSTOMIZED_PRODUCT_COMMENT`;
export const ADD_CUSTOMIZER_LOGO = `${namespace}ADD_CUSTOMIZER_LOGO`;
export const DELETE_CUSTOMIZER_LOGO = `${namespace}DELETE_CUSTOMIZER_LOGO`;
export const RESET_CUSTOMIZED_PRODUCT = `${namespace}RESET_CUSTOMIZED_PRODUCT`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const getProductCustomizationId = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CUSTOMIZER_DETAILS));
    const response = await api.get(apiEndPoints.getProductCustomizationId(id));
    const { data = {}, status = false } = response.data;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CUSTOMIZER_DETAILS));
  }
};
export const fetchCustomizerDetails = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(CUSTOMIZER_DETAILS));
    const response = await api.get(apiEndPoints.getProductCustomizeData(id));
    const { data = {}, status = false } = response.data;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(CUSTOMIZER_DETAILS));
  }
};

export const saveCustomizerDetails =
  ({ id, payload }) =>
  async (dispatch) => {
    try {
      dispatch(addActionLoader(SAVE_CUSTOMIZER_DETAILS));

      const response = await api.post(
        apiEndPoints.saveProductCustomizeData(id),
        payload
      );

      const { data } = response;

      if (response?.data?.status === 200 || data.status) {
        dispatch(
          showSnackbar({
            snackbarMessage: response?.data?.message,
            type: snackbarTypes.SUCCESS,
          })
        );
        return data;
      }
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.ERROR,
        })
      );
      return data;
    } catch (error) {
      dispatch(setApiError(error));

      return false;
    } finally {
      dispatch(removeActionLoader(SAVE_CUSTOMIZER_DETAILS));
    }
  };

export const getCustomizedDraftList = () => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_CUSTOMIZED_DRAFT_LIST));
    const response = await api.get(`${apiEndPoints.getCustomizedDraftList}`);
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data?.data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_CUSTOMIZED_DRAFT_LIST));
  }
};

export const getCustomizerProductSummary = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(GET_CUSTOMIZER_PRODUCT_SUMMARY));
    const response = await api.get(
      `${apiEndPoints.getCustomizeProductSummary}?camera_angle_id=${payload?.camerAngelId}&product_customisation_id=${payload?.cuzomizationId}`
    );
    const { data = {}, status = false } = response.data;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(GET_CUSTOMIZER_PRODUCT_SUMMARY));
  }
};

export const getCustomizedDraftOrderDetails =
  (customizerId) => async (dispatch) => {
    try {
      dispatch(addActionLoader(GET_CUSTOMIZED_DRAFT_ORDER_DETAILS));
      const response = await api.get(
        apiEndPoints.getCustomizedDraftOrderDetails(customizerId)
      );
      const { data = {}, status = false } = response.data;
      if (status) {
        return data;
      }
      return false;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(GET_CUSTOMIZED_DRAFT_ORDER_DETAILS));
    }
  };

export const updateCustomizedProductQuantity =
  (payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(UPDATE_CUSTOMIZED_PRODUCT_QUANTITY));

      const response = await api.post(
        apiEndPoints.updateCustomizedProductQuantity,
        payload
      );
      const { data } = response;

      if (response?.data?.status === 200 || data.status) {
        dispatch(
          showSnackbar({
            snackbarMessage: response?.data?.message,
            type: snackbarTypes.SUCCESS,
          })
        );
        return data;
      }
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.ERROR,
        })
      );
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(UPDATE_CUSTOMIZED_PRODUCT_QUANTITY));
    }
  };

export const transformDraftToOrder = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(TRANSFORM_DRAFT_TO_ORDER));

    const response = await api.post(
      apiEndPoints.transformDraftToOrder,
      payload
    );
    const { data } = response;

    if (response?.data?.status === 200 || data.status) {
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(TRANSFORM_DRAFT_TO_ORDER));
  }
};

export const addUpdateCustomizedDraftOrderDiscount =
  (payload) => async (dispatch) => {
    try {
      dispatch(addActionLoader(ADD_UPDATE_CUSTOMIZATION_DISCOUNT));
      const response = await api.post(
        apiEndPoints.addUpdateCustomizedDraftOrderDiscount,
        payload
      );
      const { data } = response;

      if (response?.data?.status === 200 || data.status) {
        dispatch(
          showSnackbar({
            snackbarMessage: response?.data?.message,
            type: snackbarTypes.SUCCESS,
          })
        );
        return data;
      }
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.ERROR,
        })
      );
      return data;
    } catch (error) {
      dispatch(setApiError(error));
      return false;
    } finally {
      dispatch(removeActionLoader(ADD_UPDATE_CUSTOMIZATION_DISCOUNT));
    }
  };

export const updateCustomizedProductComment = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(UPDATE_CUSTOMIZED_PRODUCT_COMMENT));
    const response = await api.post(
      apiEndPoints.UpdateCustomizedProductComment,
      payload
    );
    const { data } = response;

    if (response?.data?.status === 200 || data.status) {
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(UPDATE_CUSTOMIZED_PRODUCT_COMMENT));
  }
};

export const addCustomizerLogo = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(ADD_CUSTOMIZER_LOGO));

    const response = await api.post(apiEndPoints.addCustomizerLogo, payload);
    const { data } = response;

    if (response?.data?.status === 200 || data.status) {
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(ADD_CUSTOMIZER_LOGO));
  }
};
export const deleteCustomizerLogo = (id) => async (dispatch) => {
  try {
    dispatch(addActionLoader(DELETE_CUSTOMIZER_LOGO));
    const response = await api.delete(apiEndPoints.deleteCustomizerLogo(id));
    const { data: { status = false, message = '', data = [] } = {} } = response;
    if (status) {
      dispatch(
        showSnackbar({
          snackbarMessage: message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: message,
        type: snackbarTypes.ERROR,
      })
    );
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    const { response: { data = {} } = {} } = error;
    return data;
  } finally {
    dispatch(removeActionLoader(DELETE_CUSTOMIZER_LOGO));
  }
};

export const resetCustomizedProduct = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(RESET_CUSTOMIZED_PRODUCT));

    const response = await api.post(
      apiEndPoints.resetCustomizedProduct,
      payload
    );
    const {
      data: { data = {}, message = '', status },
    } = response;

    if (response?.data?.status === 200 || status) {
      dispatch(
        showSnackbar({
          snackbarMessage: response?.data?.message,
          type: snackbarTypes.SUCCESS,
        })
      );
      return data;
    }
    dispatch(
      showSnackbar({
        snackbarMessage: response?.data?.message,
        type: snackbarTypes.ERROR,
      })
    );
    return data;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(RESET_CUSTOMIZED_PRODUCT));
  }
};
