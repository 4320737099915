export const footerColumns = {
  Column2: {
    title: 'All Products',
    fields: [
      'Collections',
      'Deliveries',
      'Categories',
      'Pedals',
      'Wheels',
      'Tools+Pumps',
      'Components',
    ],
  },
  Column3: {
    title: 'My Account',
    fields: ['My Account', 'View Cart', 'My Suggestion', 'Logout'],
  },
  Column4: {
    title: 'Our Policies',
    fields: [
      'About',
      'Help',
      'Contact',
      'Terms and Conditions',
      'MyAtOnce 2021',
    ],
  },
};

const namespace = 'pages/layout/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const HEADER_MENU_REQUEST = `${namespace}HEADER_MENU_REQUEST`;
export const SET_HEADER1_MENU_OPTIONS = `${namespace}SET_HEADER1_MENU_OPTIONS`;
export const SET_LOGGEDIN_USER_INFO = `${namespace}SET_LOGGEDIN_USER_INFO`;
export const CUSTOMER_SUPPORT_DATA_REQUEST = `${namespace}CUSTOMER_SUPPORT_DATA_REQUEST`;
export const SET_CUSTOMER_SUPPORT_DATA = `${namespace}SET_CUSTOMER_SUPPORT_DATA`;
export const GET_DEPARTMENT_LOGO = `${namespace}GET_DEPARTMENT_LOGO`;
export const GET_DEPARTMENT_CATAGORIES = `${namespace}GET_DEPARTMENT_CATAGORIES`;
export const GET_DEPARTMENT_DELIVERIES = `${namespace}GET_DEPARTMENT_DELIVERIES`;
export const GET_DEPARTMENT_CATAGORY = `${namespace}GET_DEPARTMENT_CATAGORY`;
export const NOTIFICATION_REQUEST = `${namespace}NOTIFICATION_REQUEST`;
export const NOTIFICATION_VIEWALL_REQUEST = `${namespace}NOTIFICATION_VIEWALL_REQUEST`;
export const NOTIFICATION_DISMISSALL_REQUEST = `${namespace}NOTIFICATION_DISMISSALL_REQUEST`;
export const ADVANCE_FILTER_REQUEST = `${namespace}ADVANCE_FILTER_REQUEST`;
export const MARK_VIEWED_NOTIFICATION = `${namespace}MARK_VIEWED_NOTIFICATION`;
export const CHECK_NEW_NOTIFICATION_EXIST = `${namespace}CHECK_NEW_NOTIFICATION_EXIST`;
export const CHECK_NEW_EMAIL_NOTIFICATION_EXIST = `${namespace}CHECK_NEW_EMAIL_NOTIFICATION_EXIST`;
export const CHANGE_PASSWORD_REQUEST = `${namespace}CHANGE_PASSWORD_REQUEST`;
export const SET_CHECK_NEW_NOTIFICATION = `${namespace}SET_CHECK_NEW_NOTIFICATION`;
export const SET_CHECK_NEW_EMAIL_NOTIFICATION = `${namespace}SET_CHECK_NEW_EMAIL_NOTIFICATION`;
