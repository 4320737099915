import { useProductCustomizer } from 'pages/customization/product-customizer-hooks';
import { isJsonString } from 'utils/utils';

export const useCustomizerHandler = () => {
  const { isPartConfigured } = useProductCustomizer();

  const initializeCustomizerCanvas = ({
    customiserData,
    canvas,
    productCustomizer,
    defaultCameraAngle,
  }) => {
    if (canvas.current) {
      try {
        let sectionsInfo = customiserData?.sections[0]?.sub_sections?.map(
          (item) => item.value
        );
        let decalsinfo = customiserData?.sections[0]?.sub_sections?.map(
          (item) => `${item.value}_decal`
        );

        productCustomizer.init(canvas.current);
        productCustomizer.runViewer();
        productCustomizer.pushInputData({
          dataType: 'RESOURCE_DATA',
          resourceType: 'SHOW_PIECE',
          resourceData: {
            resourceId: customiserData?.resource_id,
            url: customiserData?.resource_url,
            // url: 'https://cdn.maotest.com/customizer/meshes/test.glb',
            modelTexture: customiserData?.model_texture,
            sectionsInfo: sectionsInfo,
            decalsInfo: decalsinfo,
          },
        });
        productCustomizer.pushInputData({
          dataType: 'RESOURCE_DATA',
          resourceType: 'NODE_MATERIAL',
          resourceData: {
            // resourceId: customiserData?.decals_resource?.resource_id,
            resourceId: '__DECAL_MATERIAL__',
            // url: customiserData?.decals_resource?.url,
            url: 'https://cdn.maotest.com/customizer/materials/artworkMaterial.json',
          },
        });
        customiserData?.materials?.forEach((material) => {
          productCustomizer.pushInputData({
            dataType: 'RESOURCE_DATA',
            resourceType: 'NODE_MATERIAL',
            resourceData: {
              resourceId: material?.value,
              url: material?.url,
            },
          });
        });

        productCustomizer.pushInputData({
          dataType: 'RESOURCE_DATA',
          resourceType: 'ENVIRONMENT_LIGHT',
          resourceData: {
            resourceId: 'defaultEnvironment',
            url: 'https://cdn.maotest.com/customizer/textures/LightSchemes/scene.env',
          },
        });
        productCustomizer.pushInputData({
          dataType: 'SCENE_DATA',
          SCENE_DRAWER: {
            environmentTexture: 'defaultEnvironment',
            clearColor: '#F4F4F4',
            environmentIntensity: 3,
          },
        });
        productCustomizer.pushInputData({
          dataType: 'SCENE_DATA',
          MESH_SELECTOR: {
            meshSelectionEnabled: true,
          },
        });
        updateEachSection({ customiserData, productCustomizer });
        setTimeout(() => {
          if (defaultCameraAngle) {
            // changeCameraAngle({ productCustomizer, defaultCameraAngle });
            goToSection({
              productCustomizer,
              sectionValue: defaultCameraAngle,
            });
          } else {
            goToSection({
              productCustomizer,
              sectionValue: customiserData?.sections[0]?.sub_sections[0].value,
            });
          }
        }, 1500); // Simulating rendering delay
      } catch (error) {
        console.error('An error occurred in Loading the Customizer', error);
      }
    }
  };
  const changeCameraAngle = ({ productCustomizer, cameraAngle }) => {
    productCustomizer.pushInputData({
      dataType: 'SCENE_DATA',
      ARC_CAMERA: {
        viewPositionChange: {
          goToSide: cameraAngle,
        },
      },
    });
  };
  const highlightSection = ({ productCustomizer, sectionValue }) => {
    productCustomizer.pushInputData({
      dataType: 'SCENE_DATA',
      MESH_SELECTOR: {
        highlightSectionById: sectionValue, // Where section is id of section
      },
    });
  };
  const removeHightLight = (productCustomizer) => {
    productCustomizer.pushInputData({
      dataType: 'SCENE_DATA',
      MESH_SELECTOR: {
        highlightSectionById: 'none',
      },
    });
  };
  const goToSection = ({ productCustomizer, sectionValue }) => {
    productCustomizer.pushInputData({
      dataType: 'SCENE_DATA',
      ARC_CAMERA: {
        viewPositionChange: {
          goToSection: sectionValue, // position is the left/right/front/back/top or section id.
        },
      },
    });
  };
  const colorhandler = ({
    productCustomizer,
    showPieceId,
    sectionId,
    assignedMaterialId,
    sectionColor,
  }) => {
    productCustomizer.pushInputData({
      dataType: 'CONFIGURATION_DATA',
      SECTION_DRAWER: {
        showPieceId: showPieceId,
        sectionId: sectionId,
        assignedMaterialId: assignedMaterialId,
        sectionColor: sectionColor,
      },
    });
  };
  const logoHandler = ({
    productCustomizer,
    showPieceId,
    selectedPart,
    artwork,
  }) => {
    productCustomizer.pushInputData({
      dataType: 'CONFIGURATION_DATA',
      DECALS_DRAWER: {
        showPieceId: showPieceId,
        //   sectionId: selectedPart,
        decalId: `${selectedPart.value}_decal`,
        //   assignedMaterialId: 'leather',
        //   sectionColor: selectedColor || '#ff5733', // remove this once Simon fixes the bug
        artwork: {
          ...artwork,
          // url: url,
          // scaling : number,
          // offsetX : number,
          // offsetY : number
        },
        // text: '' // this is to add text
        // remove: true/false   // this is to remove the logo or text
      },
    });
  };
  const textHandler = ({
    productCustomizer,
    showPieceId,
    selectedPart,
    textValue,
    color,
    outlineColor,
    fontFamily,
    fontSize,
    fontWeight,
    selectedAlignmentValue,
  }) => {
    productCustomizer.pushInputData({
      dataType: 'CONFIGURATION_DATA',
      DECALS_DRAWER: {
        showPieceId: showPieceId,
        decalId: `${selectedPart.value}_decal`,
        text: {
          text: textValue,
          color: color,
          fontSize: Number(fontSize), // string | number,
          fontFamily: fontFamily, //string,
          fontWeight: fontWeight, //string,
          fontStyle: 'italic', //string,
          //   outlineWidth: number,
          // offsetX: 0, // aligned to left
          // offsetY: 0, // aligned to top
          //   offsetY: number,
          //   scaling: number,
          ...selectedAlignmentValue,
          outlineColor: outlineColor,
          outlineWidth: 5,
          scaling: 1.5,
        },
      },
    });
  };
  const updateEachSection = ({ customiserData, productCustomizer }) => {
    // check if anything is added to parts
    customiserData?.sections[0]?.sub_sections?.forEach((part) => {
      if (isPartConfigured(part)) {
        if (part?.material || part?.color) {
          colorhandler({
            productCustomizer,
            showPieceId: customiserData?.resource_id,
            sectionId: part?.value,
            assignedMaterialId: part?.material?.value,
            sectionColor: part?.color,
          });
        }

        if (part?.text?.value) {
          textHandler({
            productCustomizer: productCustomizer,
            showPieceId: customiserData?.resource_id,
            selectedPart: part,
            textValue: part?.text?.value,
            fontFamily: part?.text?.font_family,
            fontSize: part?.text?.font_size,
            fontWeight: part?.text?.font_weight,
            selectedAlignmentValue: part?.text?.alignment,
            color: part?.text?.color,
            outlineColor: part?.text?.outlineColor,
          });
        } else {
          textHandler({
            productCustomizer: productCustomizer,
            showPieceId: customiserData?.resource_id,
            selectedPart: part,
            textValue: '',
            fontFamily: 'Open Sans',
            fontSize: '12',
            fontWeight: 300,
            selectedAlignmentValue: {
              offsetX: -1, // aligned to left
              offsetY: 0, // aligned to top
            },
            color: '#ffff',
            outlineColor: '#ffff',
          });
        }
        if (part?.logo?.logo_url) {
          logoHandler({
            productCustomizer,
            showPieceId: customiserData?.resource_id,
            selectedPart: part,
            artwork: {
              url: part?.logo?.logo_url,
              ...part?.logo?.alignment,
            },
          });
        } else {
          logoHandler({
            productCustomizer: productCustomizer,
            showPieceId: customiserData?.resource_id,
            selectedPart: part,
            remove: true,
          });
        }
      }
    });
  };
  return {
    removeHightLight,
    colorhandler,
    textHandler,
    logoHandler,
    initializeCustomizerCanvas,
    changeCameraAngle,
    goToSection,
    highlightSection,
    updateEachSection,
  };
};
