export const addFontsFromCDNFile = async (fontName, fileLink) => {
  try {
    const fontFace = new FontFace(fontName, `url(${fileLink})`);
    await fontFace.load(); // Wait for the font to load
    document.fonts.add(fontFace); // Add the font to the document
    return true; // Return true if successful
  } catch (error) {
    console.error('Error loading font:', error);
    return false; // Return false if an error occurs
  }
};

export const addFontsToLinkTag = (link) => {
  const linkElement = document.createElement('link');
  linkElement.href = link;
  linkElement.rel = 'stylesheet';
  linkElement.type = 'text/css';
  // Append to document head
  document.head.appendChild(linkElement);
};
