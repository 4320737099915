import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Navigate, Outlet, useRoutes, useMatch } from 'react-router-dom';
import { loggedInUserSelector } from 'redux/selectors';
import { SnackbarProvider } from 'notistack';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { PrivateScreen, PublicScreen } from './screen-names';
import { isAuthenticated } from 'utils/auth';
import { responsiveBreakpoints } from 'utils/utils';
import { useSelector } from 'react-redux';
import { ErrorBoundary } from 'components/error-boundary/error-boundary';
import ViewModal from 'pages/view-modal/view-modal';

const Header2Public = lazy(() => import('pages/send-email/send-email'));
const InfoModal = lazy(() => import('components/info-modal/info-modal'));
const AlertModal = lazy(() => import('components/modals/alert/alert-modal'));
const WatchTour = lazy(() => import('pages/layout/watch-tour/watch-tour'));
const BottomNavigationMenu = lazy(() =>
  import('pages/layout/bottom-navigation')
);
const WelcomeBar = lazy(() => import('pages/layout/welcome-bar/welcomeBar'));
const SnackBar = lazy(() => import('components/snackbar'));
const RecoverAccount = lazy(() =>
  import('pages/auth/recover-password/recover-account')
);
const NewAccountRequest = lazy(() =>
  import('pages/auth/new-account/new-account')
);
const Login = lazy(() => import('pages/auth/login/login'));
const Footer = lazy(() => import('pages/layout/footer'));
const Header = lazy(() => import('pages/layout/header'));
const SendEmail = lazy(() => import('pages/send-email/send-email'));
const ViewSuggestions = lazy(() =>
  import('pages/view-sugestions/view-suggestions')
);
const Customization = lazy(() => import('pages/customization/customization'));
const CustomizationDetails = lazy(() =>
  import('pages/customization/customization-details')
);
const CustomizationOrderListing = lazy(() =>
  import('pages/customization-order/customization-order-listing')
);
const CustomizationOrderDetails = lazy(() =>
  import('pages/customization-order/customization-order-details')
);
const MarketplaceOrders = lazy(() =>
  import('pages/marketplace/marketplace-orders')
);
const ManageWarehouses = lazy(() =>
  import('pages/marketplace/manage-warehouses')
);
const Accounting = lazy(() => import('pages/marketplace/accounting'));
const MarketplaceInventory = lazy(() =>
  import('pages/marketplace/marketplace-inventory')
);
const ShipViaSettings = lazy(() =>
  import('pages/marketplace/shipvia-settings')
);
const SendInvoices = lazy(() => import('pages/marketplace/send-invoices'));
const SharedDraftDetails = lazy(() =>
  import('pages/drafts/shared-drafts-details')
);
const Customers = lazy(() => import('pages/customers/customers'));
const DraftDetails = lazy(() => import('pages/drafts/draft-details'));
const OrderDetails = lazy(() => import('pages/orders/order-details'));
const SuggestionDetails = lazy(() =>
  import('pages/suggestions/suggestion-details')
);
const EmailCenter = lazy(() => import('pages/email-center/email-center'));
const ReceivedEmail = lazy(() =>
  import('pages/email-center/received/received')
);
const SentEmail = lazy(() => import('pages/email-center/sent/sent'));
const DraftEmail = lazy(() => import('pages/email-center/draft/draft'));
const SystemEmail = lazy(() => import('pages/email-center/system/system'));
const ScheduledEmail = lazy(() =>
  import('pages/email-center/scheduled/scheduled')
);
const EmailCenterMessages = lazy(() =>
  import('pages/email-center/component/email-center-messages')
);
const Suggestions = lazy(() => import('pages/suggestions/suggestions'));
const Drafts = lazy(() => import('pages/drafts/drafts'));
const Home = lazy(() => import('pages/home/home'));
const Dashboard = lazy(() => import('pages/dashboard/dashboard'));
const Reports = lazy(() => import('pages/my-attache/reports'));
const Profile = lazy(() => import('pages/profile/profile'));
const Catalog = lazy(() => import('pages/catalog/catalog'));
const Lookbooks = lazy(() => import('pages/lookbooks/lookbooks'));
const LookbookDetails = lazy(() =>
  import('pages/lookbooks/lookbook-detail/lookbook-details')
);
const MyAttacheForms = lazy(() => import('pages/my-attache/forms/forms'));
const Marketing = lazy(() => import('pages/my-attache/marketing/marketing'));
const Resources = lazy(() => import('pages/my-attache/resources/resources'));
const Linesheets = lazy(() => import('pages/my-attache/linesheets/linesheets'));
const Smu = lazy(() => import('pages/my-attache/smu/smu'));
const Orders = lazy(() => import('pages/orders/orders'));
const Help = lazy(() => import('pages/help/help'));
const AboutCompany = lazy(() =>
  import('pages/help/about-company/about-company')
);
const FrequentlyAskedQuestions = lazy(() => import('pages/help/faqs/faqs'));
const HelpVideo = lazy(() => import('pages/help/help-video/help-video'));
const ReportAskOrRequest = lazy(() =>
  import('pages/help/report-ask-request/report-ask-request')
);
const MyAtOnce = lazy(() => import('pages/help/myatonce/myatonce'));
const Fulfilment = lazy(() => import('pages/fulfillment/fulfilment'));
const BuyerEditRequest = lazy(() =>
  import('pages/customers/buyer-edit-request/buyer-edit-request')
);
const AddEditRetailer = lazy(() =>
  import('pages/customers/components/add-edit-retailer/add-edit-retailer')
);
const MarketplaceWebhook = lazy(() =>
  import('pages/marketplace/marketplace-webhook')
);
const SiteStatusPage = lazy(() =>
  import('pages/site-status-page/site-status-page')
);
const BuyAgainPage = lazy(() => import('pages/home/buy-again/buy-again'));
const HotPage = lazy(() => import('pages/home/hot/hot'));
const PdfViewerPage = lazy(() => import('pages/pdfViewer/pdfViewerPage'));

const PrivateOutlet = () => {
  const isDraft = useMatch('/drafts/:id');
  const isOrder = useMatch('/order-payment/:id');
  const isCatalog = useMatch('/catalog');
  const isOrderListing = useMatch('/order-payment');
  const isCustomizationDraft = useMatch('/customization/:id');
  const [value, setValue] = useState(0);
  const [watchTourCompleted, setWatchTourCompleted] = useState(true);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const { mobile, tablet } = responsiveBreakpoints();
  const userInfo = useSelector(loggedInUserSelector);
  useEffect(() => {
    if (userInfo.watchTourCompleted === false)
      setWatchTourCompleted(userInfo.watchTourCompleted);
  }, [userInfo.watchTourCompleted]);

  return isAuthenticated() ? (
    <>
      <WelcomeBar />
      <Header menuValue={value} setMenuValue={setValue} />
      <InfoModal />
      <SnackbarProvider maxSnack={5}>
        <SnackBar />
      </SnackbarProvider>
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <ErrorBoundary loggedInUser={userInfo?.username}>
          <Outlet />
        </ErrorBoundary>
      </Suspense>

      {!watchTourCompleted ? (
        <WatchTour
          setWatchTourCompleted={(bool) => {
            setWatchTourCompleted(bool);
            if (bool) setShowAlertModal(true);
          }}
          userInfo={userInfo}
        />
      ) : null}
      {showAlertModal ? (
        <AlertModal
          open={showAlertModal}
          setOpen={setShowAlertModal}
          type='success'
          title='You can still access the tours for the new features in our help center'
        />
      ) : null}

      {!isDraft &&
        !isOrder &&
        !isCatalog &&
        !isOrderListing &&
        !isCustomizationDraft && <Footer />}
      {mobile || tablet ? (
        <BottomNavigationMenu value={value} setValue={setValue} />
      ) : null}
    </>
  ) : (
    <Navigate to={PublicScreen.login} />
  );
};
const PrivateOutletWithoutLayout = () => {
  const userInfo = useSelector(loggedInUserSelector);
  return isAuthenticated() ? (
    <Suspense
      fallback={
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <ErrorBoundary loggedInUser={userInfo?.username}>
        <Outlet />
      </ErrorBoundary>
    </Suspense>
  ) : (
    <Navigate to={PublicScreen.login} />
  );
};
const PublicOutlet = () => {
  return (
    <>
      <Box sx={{ height: '31px', backgroundColor: '#1A1A1A' }}></Box>
      <Header2Public />
      <Suspense
        fallback={
          <Box
            sx={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </>
  );
};

const AppRoutes = () => {
  const routes = useRoutes([
    { path: PublicScreen.login, element: <Login /> },
    { path: PublicScreen.register, element: <NewAccountRequest /> },
    { path: PublicScreen.recover, element: <RecoverAccount /> },
    {
      path: PublicScreen.viewSuggestions,
      element: <PublicOutlet />,
      children: [
        { path: '', element: <ViewSuggestions /> },
        { path: ':id', element: <ViewSuggestions /> },
      ],
    },
    {
      path: '/',
      element: <PrivateOutlet />,
      children: [
        { path: PrivateScreen.home, element: <Home /> },
        { path: PrivateScreen.buyAgain, element: <BuyAgainPage /> },
        { path: PrivateScreen.hot, element: <HotPage /> },
        { path: PrivateScreen.profile, element: <Profile /> },
        { path: PrivateScreen.lineSheets, element: <Linesheets /> },
        { path: PrivateScreen.smu, element: <Smu /> },
        { path: PrivateScreen.forms, element: <MyAttacheForms /> },
        { path: PrivateScreen.marketing, element: <Marketing /> },
        { path: PrivateScreen.resources, element: <Resources /> },
        { path: PrivateScreen.help, element: <Help /> },
        { path: PrivateScreen.helpContact, element: <AboutCompany /> },
        { path: PrivateScreen.helpTutorials, element: <HelpVideo /> },
        { path: PrivateScreen.helpSupport, element: <ReportAskOrRequest /> },
        { path: PrivateScreen.helpMyAtOnce, element: <MyAtOnce /> },
        { path: PrivateScreen.helpFaqs, element: <FrequentlyAskedQuestions /> },
      ],
    },
    {
      path: PrivateScreen.customers,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Customers /> },
        { path: ':selectedTab', element: <Customers /> },
        { path: PrivateScreen.buyerEditRequest, element: <BuyerEditRequest /> },
        { path: PrivateScreen.addRetailer, element: <AddEditRetailer /> },
        { path: PrivateScreen.editRetailer, element: <AddEditRetailer /> },
      ],
    },

    {
      path: PrivateScreen.emailCenter,
      element: <PrivateOutlet />,
      children: [
        {
          path: '',
          element: <EmailCenter />,
          children: [
            { path: 'received', element: <ReceivedEmail /> },
            { path: 'sent', element: <SentEmail /> },
            { path: 'scheduled', element: <ScheduledEmail /> },
            { path: 'draft', element: <DraftEmail /> },
            { path: ':emailType/:emailId', element: <EmailCenterMessages /> },
          ],
        },
      ],
    },

    {
      path: PrivateScreen.dashboard,
      element: <PrivateOutlet />,
      children: [{ path: '', element: <Dashboard /> }],
    },
    {
      path: PrivateScreen.reports,
      element: <PrivateOutlet />,
      children: [{ path: '/my-attache/reports', element: <Reports /> }],
    },
    {
      path: PrivateScreen.drafts,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Drafts /> },
        { path: ':id', element: <DraftDetails /> },
      ],
    },
    {
      path: PrivateScreen.suggestions,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Suggestions /> },
        { path: ':id', element: <SuggestionDetails /> },
      ],
    },

    {
      path: PrivateScreen.catalog,
      element: <PrivateOutlet />,
      children: [{ path: '', element: <Catalog /> }],
    },

    {
      path: '*',
      element: <PrivateOutlet />,
      children: [
        { path: '*', element: <SiteStatusPage isNotFoundPage={true} /> },
      ],
    },
    {
      path: PrivateScreen.lookbooks,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Lookbooks /> },
        { path: ':id/:name', element: <LookbookDetails /> },
      ],
    },
    {
      path: PrivateScreen.orders,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Orders /> },
        { path: ':id', element: <OrderDetails /> },
        { path: ':retailerID/:mode', element: <Orders /> },
      ],
    },
    {
      path: PrivateScreen.sharedDrafts,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Drafts /> },
        { path: ':id', element: <SharedDraftDetails /> },
      ],
    },
    {
      path: PrivateScreen.sendEmail,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <SendEmail /> },
        { path: ':page', element: <SendEmail /> },
        { path: ':page/:scheduledEmailId', element: <SendEmail /> },
      ],
    },
    {
      path: PrivateScreen.marketplace,
      element: <PrivateOutlet />,
      children: [
        {
          path: 'orders',
          element: <MarketplaceOrders />,
        },
        {
          path: 'warehouses',
          element: <ManageWarehouses />,
        },
        {
          path: 'accounting',
          element: <Accounting />,
        },
        {
          path: 'inventory',
          element: <MarketplaceInventory />,
        },
        {
          path: 'ship-via-settings',
          element: <ShipViaSettings />,
        },
        {
          path: 'send-invoices',
          element: <SendInvoices />,
        },
        {
          path: 'shopify-orders',
          element: <MarketplaceWebhook />,
        },
      ],
    },
    {
      path: PrivateScreen.fulfilment,
      element: <PrivateOutlet />,
      children: [{ path: '', element: <Fulfilment /> }],
    },
    {
      path: PrivateScreen.pdfViewer,
      element: <PrivateOutletWithoutLayout />,
      children: [{ path: '', element: <PdfViewerPage /> }],
    },
    {
      path: PrivateScreen.customization,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <Customization /> },
        { path: ':customizerId', element: <CustomizationDetails /> },
      ],
    },
    {
      path: PrivateScreen.customizationOrderListing,
      element: <PrivateOutlet />,
      children: [
        { path: '', element: <CustomizationOrderListing /> },
        { path: ':customizerId', element: <CustomizationOrderDetails /> },
      ],
    },
    {
      path: PrivateScreen.viewModal,
      element: <PrivateOutlet />,
      children: [{ path: '', element: <ViewModal /> }],
    },
  ]);
  return routes;
};

export default AppRoutes;
