import FileSaver from 'file-saver';
import { api } from '../api';
import * as XLSX from 'xlsx';
import isEmpty from 'lodash/isEmpty';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export const downloadFile = async (url, fileName = 'download') => {
  const blob = await api.get(url).then((r) => r.blob());
  FileSaver.saveAs(blob, fileName);
};

export const getQueryParams = (data) => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const exportToCSV = (
  headerObj = {}, // must contains table Headers Name with there respective keys
  dataArr = [], // must be an array of objects
  fileName = 'download' // file Name
) => {
  if (!isEmpty(dataArr) && !isEmpty(headerObj)) {
    const mappedLogs = dataArr.map((row) => {
      const temp = {};
      Object.entries(row).map((entity) => {
        temp[headerObj[entity[0]]] = entity[1];
      });
      return { ...temp };
    });

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(mappedLogs);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
};

export const responsiveBreakpoints = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.down('md'));
  const laptop = useMediaQuery(theme.breakpoints.down('lg'));
  const desktop = useMediaQuery(theme.breakpoints.down('xl'));
  const mainWidth = useMediaQuery(theme.breakpoints.down('lg'))
    ? `95vw`
    : '1355px';

  return {
    theme,
    mobile,
    tablet,
    laptop,
    desktop,
    mainWidth,
  };
};

export const isWhite = (color) => {
  if (isEmpty(color)) {
    return true;
  }
  const lowerCaseColor = `#${color.toLowerCase()}`;
  return (
    lowerCaseColor === '#ffffff' ||
    lowerCaseColor === '#fff' ||
    lowerCaseColor === '#ffffffff' ||
    lowerCaseColor === '#fffffffff' ||
    lowerCaseColor === '#fffffffffff'
  );
};

// Function to check if the string contains "white"
export const containsWhiteText = (str) => {
  const lowerCaseStr = str.toLowerCase();
  return lowerCaseStr.includes('whit');
};

// check if its json string
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// check if entered value exceeds the given value
export const isEnteredValueExceeded = (str, threshold) => {
  if (typeof str === 'string' && typeof threshold == 'number') {
    if (str?.length > threshold) {
      return true;
    } else return false;
  }
};
export const getStateData = async ({ payload }) => {
  const data = await api.get(`getStates/${payload?.country}`);
  return data;
};
export const getCityData = async ({ payload }) => {
  const data = await api.get(`getCities/${payload?.country}/${payload?.state}`);
  return data;
};
export const getCountryData = async () => {
  const data = await api.get(`getCountry`);
  return data;
};
export const validHexCode = (hexCode) => {
  const hexRegex = /^#([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/;
  return hexRegex.test(hexCode);
};
