import React from 'react';
import PropTypes from 'prop-types';

export default function ArrowNextIcon({ fill, sx, stroke, width, height }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 12.621 22.243'
    >
      <path
        id='Icon_feather-chevron-left'
        data-name='Icon feather-chevron-left'
        d='M22.5,27l-9-9,9-9'
        transform='translate(24.621 29.121) rotate(180)'
        fill='none'
        stroke={stroke}
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='3'
      />
    </svg>
  );
}

ArrowNextIcon.propTypes = {
  fill: PropTypes.string,
  sx: PropTypes.object,
  stroke: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};
ArrowNextIcon.defaultProps = {
  fill: 'var(--primary-icon-color)',
  sx: {},
  width: '12.621',
  height: '22.243',
  stroke: '#000',
};
