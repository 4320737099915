import React from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  IconButton,
} from '@mui/material';
import { validHexCode } from 'utils/utils';

const ProductViewSummary = ({ summaryData }) => {
  return (
    <Box sx={{ height: '100%', overflow: 'scroll', paddingRight: '30px' }}>
      <Typography variant='h2'>{summaryData?.camera_angle_name}</Typography>
      <Stack>
        {summaryData?.sub_sections?.map((part, i) => (
          <Box key={i}>
            <Box
              sx={{
                height: '25px',
                backgroundColor: '#EFFAFF',
                border: '1px solid #CEEEFF',
                width: 'fit-content',
                padding: '0px 12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '12px 0px',
              }}
            >
              <Typography
                variant='body1'
                sx={{ color: '#006AA2', fontWeight: '700' }}
              >
                {part?.label}
              </Typography>
            </Box>
            <TableContainer>
              <TableBody>
                {part?.data?.map((item, j) => (
                  <TableRow key={j}>
                    <TableCell
                      sx={{
                        border: 'none',
                        fontSize: '12px',
                        color: '#8E8E8E',
                        padding: '0px',
                        width: '150px',
                        lineHeight: '24px',

                        whiteSpace: 'wrap',
                        wordBreak: 'break-word',
                      }}
                    >
                      {item?.label}
                    </TableCell>
                    {item?.is_url ? (
                      <TableCell
                        sx={{
                          border: 'none',
                          fontSize: '12px',
                          color: '#000000',
                          padding: '0px',
                          whiteSpace: 'wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        <a
                          href={item?.value}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {item?.value.split('/').pop().split('_').pop()}
                        </a>
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{
                          border: 'none',
                          fontSize: '12px',
                          color: '#000000',
                          padding: '0px',
                          whiteSpace: 'wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {validHexCode(item?.value) ? (
                          <Box
                            sx={{
                              width: '13px',
                              height: '13px',
                              backgroundColor: item?.value,
                              display: 'inline-block',
                              marginRight: '4px',
                              border: '0.7px solid var(--primary-border-color)',
                            }}
                          />
                        ) : null}
                        {item?.value}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </TableContainer>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

ProductViewSummary.propTypes = {
  summaryData: PropTypes.object,
};
ProductViewSummary.defaultProps = {
  summaryData: {},
};
export default ProductViewSummary;
