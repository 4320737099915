export const PublicScreen = {
  login: '/login',
  register: '/register',
  recover: '/recover',
  viewSuggestions: '/view-suggestions',
};

export const PrivateScreen = {
  home: '/',
  buyAgain: '/home/buy-again',
  hot: '/home/hot',
  customers: '/my-customer',
  dashboard: '/dashboard',
  profile: '/profile',
  lookbooks: '/lookbooks',
  myAttache: '/my-attache',
  reports: '/my-attache/reports',
  drafts: '/drafts',
  sharedDrafts: '/shared-draft',
  suggestions: '/suggestions',
  marketing: '/my-attache/markets',
  resources: '/my-attache/resources',
  lineSheets: '/my-attache/linesheets',
  smu: '/my-attache/smu',
  forms: '/my-attache/forms',
  orders: '/order-payment',
  orderSuccess: '/order-success',
  catalog: '/catalog',
  help: '/help',
  helpContact: '/help/contact',
  helpTutorials: '/help/tutorials',
  helpSupport: '/help/support',
  helpMyAtOnce: '/help/myatonce',
  marketplace: '/marketplace',
  fulfilment: '/fulfilment',
  buyerEditRequest: 'edit-request',
  helpFaqs: '/help/faqs',
  sendEmail: '/send-email',
  addRetailer: 'retailers/add',
  editRetailer: 'retailers/:retailerId',
  emailCenter: '/email-center',
  emailCenterIcon: '/email-center/received',
  emailCenterMessagesReceived: 'received/:emailId',
  emailCenterMessagesSent: 'sent/:emailId',
  emailCenterMessagesScheduled: 'scheduled/:emailId',
  pdfViewer: '/pdfViewer',
  customization: '/customization',
  customizationOrderListing: '/customization-order-listing',
  viewModal: '/view-modal',
};
